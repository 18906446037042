<template>
  <div class="height-5-persents">
    <div class="row">

      <input type="button" class="button_simple" @click="openMain" value="Главная">
      <!-- <input type="button" class="button_simple" @click="openSettings" value="Настройки"> -->

    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.marg {
  margin: 3px;
}

.min-border {
  min-width: 120px;
  width: 120px;
  min-height: 80px;
  height: 80px;
}

.max-border {
  max-width: 120px;
}

.full-lock {
  max-width: 120px;
  min-width: 120px;
  min-height: 80px;
  max-height: 80px;
}

.scroll {
  overflow: auto;
  overflow-y: auto;
  /* Trigger vertical scroll    */
  overflow-x: hidden;
  /* Hide the horizontal scroll */
}

.list-questions {
  max-height: 600px;
  width: 800px;
}

.max-width-500 {
  width: 500px;
}

.max-width-700 {
  width: 500px;
}


.list-categories {
  max-height: 600px;
  width: 500px;
}

.button_simple {
  background-color: #1d1d1d;
  color: white;
  border: none;
  border-radius: 5px;
  border-collapse: separate;
  margin: 5px;
  padding: 5px;
}

.button_simple:hover {
  background-color: #4e4e4e;
  color: white;
  border: none;
  border-radius: 5px;
  border-collapse: separate;
  margin: 5px;
  padding: 5px;
}

.checkbox_autoupdate {
  background-color: #4e4e4e;
  color: white;
  border: none;
  border-radius: 5px;
  border-collapse: separate;
  margin: 5px;
  padding: 5px;
  max-width: 200px;
}

.checkbox_autoupdate:hover {
  background-color: #4e4e4e;
  color: white;
  border: none;
  border-radius: 5px;
  border-collapse: separate;
  margin: 5px;
  padding: 5px;
  max-width: 200px;
}

#checkboxAutoUpdate {
  accent-color: #1d1d1d;
}

/*
Main colors from Android legacy


    <color name="purple_200">#4DCC95</color>
    <color name="purple_500">#1FA36A</color>
    <color name="purple_700">#13774c</color>
    <color name="teal_100">#A3A3A3</color>
    <color name="teal_200">#787878</color>
    <color name="teal_700">#3E3E3E</color>
    <color name="black">#FF000000</color>
    <color name="white">#FFFFFFFF</color>
    <color name="interface_red">#DC0E0E</color>
    <color name="interface_green">#13774c</color>
    <color name="interface_disabled">#F1F1F1</color>
    <color name="device">#F1F1F1</color>
    <color name="yellow_device">#FFF591</color>
    <color name="transparent">#00FFFFFF</color>
    <color name="hvb_stroke">#D1D1D1</color>

*/
</style>

<script>

export default {
  name: "component-navigation",
  props: ['swapComponent'],
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
   
    openMain() {
      this.swapComponent('main');
      
    },
    openSettings() {
      this.swapComponent('settings');
    },
  }
}
</script>