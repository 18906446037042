<template>
  <div>
    
    <h1>Hello bitch, is my OWN SITE</h1>
    
  </div>
</template>


<script>


export default {
  name: 'component-main',
  props: ['swapComponent', 'buildServerPath', 'securityParamsBuild'],
  components: {
    
  },
  data() {
    return {
      title: "Main",
      
    }
  },
  mounted() {
  },
  computed: {
    
  },
  methods: {

  }
};
</script>