<template>
  <div id="app">

    <component v-bind:is="navComponent" :swap-component="swapComponent"></component>

    <component v-bind:is="currentComponent" :swap-component="swapComponent" :build-server-path="buildServerPath"
      :security-params-build="securityParamsBuild"></component>

  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.marg {
  margin: 3px;
}

.min-border {
  min-width: 120px;
  width: 120px;
  min-height: 80px;
  height: 80px;
}

.max-border {
  max-width: 120px;
}

.full-lock {
  max-width: 120px;
  min-width: 120px;
  min-height: 80px;
  max-height: 80px;
}

.scroll {
  overflow: auto;
  overflow-y: auto;
  /* Trigger vertical scroll    */
  overflow-x: hidden;
  /* Hide the horizontal scroll */
}

.list-questions {
  max-height: 600px;
  width: 800px;
}

.max-width-500 {
  width: 500px;
}

.max-width-700 {
  width: 500px;
}


.list-categories {
  max-height: 600px;
  width: 500px;
}

/*
Main colors from Android legacy


    <color name="purple_200">#4DCC95</color>
    <color name="purple_500">#1FA36A</color>
    <color name="purple_700">#13774c</color>
    <color name="teal_100">#A3A3A3</color>
    <color name="teal_200">#787878</color>
    <color name="teal_700">#3E3E3E</color>
    <color name="black">#FF000000</color>
    <color name="white">#FFFFFFFF</color>
    <color name="interface_red">#DC0E0E</color>
    <color name="interface_green">#13774c</color>
    <color name="interface_disabled">#F1F1F1</color>
    <color name="device">#F1F1F1</color>
    <color name="yellow_device">#FFF591</color>
    <color name="transparent">#00FFFFFF</color>
    <color name="hvb_stroke">#D1D1D1</color>

*/
</style>

<script>

import Settings from './components/settings.vue';
import Main from './components/main.vue';
import MainMobile from './components/main-mobile.vue';
import Navigation from './components/navigation.vue';


export default {
  name: 'app',
  data() {
    return {
      serverIP: 'localhost',
      serverPort: '8999',

      currentComponent: 'main',
      navComponent: 'navigation'
    }
  },

  watch: {
    items: {
      handler(val, oldVal) {
        console.log(oldVal + ' --> ' + val)
      },
      deep: true
    }
  },

  components: {
    'main': (screen.width <= 760) ? MainMobile : Main,
    'settings': Settings,
    'navigation': Navigation,
  },

  created: function () {
    this.serverIP = this.getCookie("IPADDR");
  },

  methods: {

    isMobile() {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    securityParamsBuild() {
      var secPar = new URLSearchParams({
        API_Key: "392b4fc7-3bf2-4dd4-98c5-650671775d9d",
        APP_Version: "null",
      }).toString();

      return secPar;
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    buildServerPath() {
      return "http://" + this.serverIP + ":" + this.serverPort;
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

  },

};
</script>

<style></style>

