<template>
   <div class="relative">
          <div class="absolute left padding-element-5">
            <table class="flat-table">

              <tr>
                <td>
                  <p>IP-адрес глобального сервера:</p>
                </td>

                <td>
                  <input class="padding-element input-element-green-light" type="text" id="inputServer"
                    v-model="serverIP" ref="inputServer" value="DD-MM-YYYY">
                </td>
              </tr>

              <tr>
                <td>
                  <p>Порт глобального сервера:</p>
                </td>

                <td>
                  <input class="padding-element input-element-green-light" type="text" id="inputPort"
                    v-model="serverPort" ref="inputServer" value="DD-MM-YYYY">
                </td>
              </tr>

            </table>
          </div>


        </div>

</template>

<script>

export default {
    name: "component-settings",
    props: ['swapComponent'],
    data() {
        return {
            title: "Настройки",
        }
    },
    mounted() {
    },
    methods: {
        

    }
}

</script>